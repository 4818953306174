import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert,
    Grid,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Dialog

} from "@mui/material"
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Header from "../Header";
import {
    GridPagination,
    DataGrid,
    getGridStringOperators
  } from '@mui/x-data-grid';
import { NumericFormat,PatternFormat } from "react-number-format";
import { dateDifference, formatDate, formatDateText } from "../CommonFunctions";
import { parseISO,eachDayOfInterval, format } from "date-fns";
import { Link } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import MakePayment from "../MakePayment";

// Define your styles
const useStyles = makeStyles((theme) => ({
  duplicateRow: {
        backgroundColor: '#ffcccc',
        '&:hover': {
            backgroundColor: '#c99393 !important' ,  // Change to desired hover color
        },
  
    },
    noEntryRow: {
      backgroundColor: '#b6e6fa',
      '&:hover': {
          backgroundColor: '#8ed6f5 !important' ,  // Change to desired hover color
      },
    },

      WrongNumberRow: {
        backgroundColor: '#f5ecab',
        '&:hover': {
            backgroundColor: '#eddd66 !important' ,  // Change to desired hover color
        },

  },
  refundedRow: {
    backgroundColor: '#b3db97',
    '&:hover': {
        backgroundColor: '#9adb6b !important' ,  // Change to desired hover color
    },

},
}));

const dotStyles= (colorCode) =>{
  return {
  height: '12px',
  width: '12px',
  borderRadius: '50%',
  display: 'inline-block',
  backgroundColor:`${colorCode}`
  }
}

let last12Months=[];
const options = { month: 'short', day: 'numeric', year:'numeric' };
let totalExpectedRent=0;
let totalAmountReceived=0;
const now=new Date();
now.setDate(1);
const RoomPaymentReport=()=>{
  const currentDate=formatDate(new Date());

  const [searchStartDate,setSearchStartDate]=  useState(formatDate(now));
  const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date()));
  const [reportSummary,setReportSummary] = useState([])
  const [occupancyReport,setOccupancyReport] = useState([]);
  const [last12Months,setLast12Months] = useState([]);
  const [missingPaymentsReportSummary,setMissingPaymentsReportSummary] = useState([]);
  const [makePaymentModal,setMakePaymentModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [sortModel, setSortModel] = useState([]);
  const [missingReceiptWarning, setMissingReceiptWarning] = useState(false);
  let totalExemptAmount=0,totalTaxableAmount=0,totalSalesTax=0,totalTourismTax=0;
  let salesTax=0.07;
  let tourismTax=0.05;
  const roomsToTotalsDict={};
 
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      console.log(parseISO(searchStartDate).toLocaleDateString('en-us',options))
        
    }, [searchStartDate,searchEndDate]);
    useEffect(() => {
      getLast12Months();
    }, [currentDate]);
    
    function getLast12Months() {
      const months = [];
      const currentDate = new Date();
      currentDate.setDate(1); // Set to the first day of the current month
  
      for (let i = 0; i < 12; i++) {
          const startDate = new Date(currentDate);
          let endDate = new Date(currentDate);
          if(i!==0){
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0); // Set to the last day of the previous month
          }
          else{
            endDate=new Date();
          }
          
  
          months.push({
              startDate: new Date(startDate),
              endDate: new Date(endDate),
          });
  
          // Move to the previous month
          currentDate.setMonth(currentDate.getMonth() - 1);
      }
      console.log(months)
      setLast12Months(months)
  }
  const columns=[
    { field: 'roomid', headerName: 'Room Number', width: 140, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <Link to={
          `/payments/search?searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}&roomNumber=${currentRow.roomid}`
          } target="_blank">
            {currentRow.roomid}
       
        </Link>
        
       }
      // filterOperators: getGridStringOperators().filter(
      //   (operator) => operator.value === 'equals' 
      // )
    },
    { field: 'numberOfTenancies', headerName: 'Tenancies', width: 140, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.numberOfTenancies} thousandSeparator prefix={''} displayType="text"/>
        </>
        
       }
    },
    { field: 'totalRent', headerName: 'Total Expected Rent', width: 180, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          return <>
          <NumericFormat value={currentRow.totalRent} thousandSeparator prefix={'$'} displayType="text" style={{color:"grey"}}/>
          </>
          
         }
       },
  
      { field: 'totalAmount', headerName: 'Amount Paid', width: 180, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          return <b>
          <NumericFormat value={currentRow.totalAmount} thousandSeparator prefix={'$'} displayType="text"/>
          </b>
          
         }
       },
       { field: 'difference', headerName: 'Difference', width: 180, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          // const difference=currentRow.totalAmount-currentRow.totalRent
          return <>
          <NumericFormat value={currentRow.difference} 
          thousandSeparator prefix={'$'} displayType="text"
          style={{color: currentRow.difference<0 ? "red" : "black"}}
          />
         
          </>
          
         }
       },
   
     

  ]
  const getPaymentDetailsByDateRange = async(searchStartDate,searchEndDate) =>{
    const dateColumnFilter = 'actualPaymentDate';
    await axios.post(`${url}/payments/allPaymentsinDateRange`,{
        searchStartDate,
        searchEndDate,
        dateColumnFilter
      }).then(async(responseForTenancyDetails)=>{
        console.log(responseForTenancyDetails.data)
        if(responseForTenancyDetails && responseForTenancyDetails.data)
        setReportSummary(responseForTenancyDetails.data)
       
      })
  }
  const getMissingPaymentDetailsByDateRange = async(searchStartDate,searchEndDate) =>{
    const dateColumnFilter = 'actualPaymentDate';
    await axios.post(`${url}/getMissingPayments`,{
        searchStartDate,
        searchEndDate,
        dateColumnFilter
      }).then(async(responseForTenancyDetails)=>{
        console.log(responseForTenancyDetails.data)
        if(responseForTenancyDetails && responseForTenancyDetails.data)
        setMissingPaymentsReportSummary(responseForTenancyDetails.data)
       
      })
    }
  
    const preProcess = (data,missingData)=>{
      let idToDetails={};
      let roomToTotals={};
      for(let itr in data){
        let currentRow=data[itr];
        let amount=0;
            if(currentRow.cashAmount)
                amount=amount+parseInt(currentRow.cashAmount);
            if(currentRow.creditCardAmount)
                amount+=parseInt(currentRow.creditCardAmount);
            if(currentRow.otherAmount)
                amount+=parseInt(currentRow.otherAmount);
        if(currentRow.id in idToDetails){
          let curr=roomToTotals[currentRow.roomid]
          roomToTotals[currentRow.roomid]={
            id:currentRow.id,
            roomid:currentRow.roomid,
            numberOfTenancies: curr.numberOfTenancies,
            totalRent: curr.totalRent,
            totalAmount: curr.totalAmount + amount,
            difference: curr.totalAmount + amount - curr.totalRent
          }

        }
        else{
          idToDetails[currentRow.id] = currentRow;
          let roomid=currentRow.roomid;
          
          if(roomid in roomToTotals){
            let curr=roomToTotals[roomid];
            let difference=curr.totalAmount + amount;
            difference-=(curr.totalRent + currentRow.rate)
            roomToTotals[currentRow.roomid]={
              id:currentRow.id,
              roomid:currentRow.roomid,
              numberOfTenancies: curr.numberOfTenancies +1,
              totalRent: curr.totalRent + currentRow.rate,
              totalAmount: curr.totalAmount + amount,
              difference: difference
            }

          }
          else{
            roomToTotals[currentRow.roomid]={
              id:currentRow.id,
              roomid:currentRow.roomid,
              numberOfTenancies:1,
              totalRent:currentRow.rate,
              totalAmount: amount,
              difference: amount - currentRow.rate

            }

          }

          
        }
      }
      for(let itr in missingData){
        let currentRow=missingData[itr];
        if(currentRow.roomid in roomToTotals){
          const curr=roomToTotals[currentRow.roomid];
          roomToTotals[currentRow.roomid]={
            id:currentRow.id,
            roomid:currentRow.roomid,
            numberOfTenancies:curr.numberOfTenancies +1,
            totalRent:curr.totalRent+currentRow.rate,
            totalAmount: curr.totalAmount,

          }

        }
        else{
          roomToTotals[currentRow.roomid]={
            id:currentRow.id,
            roomid:currentRow.roomid,
            numberOfTenancies:1,
            totalRent:currentRow.rate,
            totalAmount: 0,

          }
        }
      }
      let resultArray=[];
      totalExpectedRent=0;
      totalAmountReceived=0;
      for (const key in roomToTotals) {
        resultArray.push(roomToTotals[key]);
        totalExpectedRent+=parseInt(roomToTotals[key].totalRent);
        totalAmountReceived+=parseInt(roomToTotals[key].totalAmount);
      }
      console.log(resultArray);
      return resultArray;
     }
  // Function to check for the first missing receipt number
  const findFirstMissingReceiptNumber = (sortedRows) => {
    for (let i = 1; i < sortedRows.length; i++) {
      const prevReceipt = sortedRows[i - 1].receiptNumber;
      const currentReceipt = sortedRows[i].receiptNumber;

      if (currentReceipt - prevReceipt > 1) {
        return parseInt(prevReceipt) + 1; // Return the first missing receipt number
      }
    }
    return null;
  };

  // Handle sort model change
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);

    // Check if the receiptNumber column is sorted
    const isReceiptNumberSorted = newSortModel.some(
      (model) => model.field === 'receiptNumber'
    );
    const isAscending = newSortModel.some(
      (model) =>  model.sort === 'asc'
    );

    if (isReceiptNumberSorted) {
      // Sort the rows by receipt number
      if(isAscending){
      const sortedRows = [...reportSummary].sort((a, b) => a.receiptNumber - b.receiptNumber);
      const missingReceipt = findFirstMissingReceiptNumber(sortedRows);

      if (missingReceipt) {
        // Add a row indicating a missing receipt number if there's a gap
        setReportSummary((prevRows) => [
          ...prevRows,
          {
            id: prevRows.length + 1,
            name: 'Missing Receipt Number',
            receiptNumber: `${missingReceipt} (Warning)`, //Hide the receipt number while
          },
        ]);
      }
    }
    } else {
      // Remove the "missing" row if sorting a different column
      setReportSummary((prevRows) =>
        prevRows.filter((row) => !row.receiptNumber.toString().includes('Warning'))
      );
    }
  };
  const MissingReceiptNumberWarning=()=>{
    return <>
    <React.Fragment>
      <Button variant="outlined" onClick={(e)=>{
        setMissingReceiptWarning(false)
      }}>
        
      </Button>
      <Dialog
        open={missingReceiptWarning}
        onClose={(e)=>{
          setMissingReceiptWarning(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Missing Receipt Number
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
    </>
}
  function CustomPagination(props) {
    return <>
    <b>Total Expected Rent
    <NumericFormat value={totalExpectedRent} thousandSeparator prefix={'$'} displayType="text"/></b>
        <b>Total Amount Received
        <NumericFormat value={totalAmountReceived} thousandSeparator prefix={'$'} displayType="text"/></b>
        <b>Number of rooms
        <NumericFormat value={0} thousandSeparator prefix={'$'} displayType="text"/></b>
        
        <GridPagination  {...props} /> </>;
  }
  // const handleFilterChange=(newFilter)=>{
  //   const items=newFilter.items;
  //   const filtered=items.find(item => item.field === 'roomid');
  //   const currentRow=filtered[0];
  //   if(currentRow && currentRow.value){
  //     const roomNumber = Number(currentRow.value);
  //     console.log("Room Number selected: " + roomNumber);
  //   }

  // }
  
  
  
    
    
  const classes = useStyles();

    return (
      <>
      <Header/>
        
        <br></br>
        <Paper style={{margin:"0% 10%", padding:"1.2% 0%"}} >
          <Box style={{margin:"0% 10%", display:"flex" , justifyContent:"space-around"}}>
            <TextField id="outlined-basic" label="Start Date" variant="outlined" 
            type="date" value={searchStartDate} onChange={(e)=>{
                  setSearchStartDate(e.target.value);
              }}
            />
            <TextField id="outlined-basic" label="End Date" variant="outlined" 
            type="date" value={searchEndDate} onChange={(e)=>{
              setSearchEndDate(e.target.value);
              }}
            />
            {/* <b className="mt-3"
            > Selected for {dateDifference(searchStartDate,searchEndDate) + " days"}</b> */}
            <Button variant="contained" onClick={(e)=>{
              getPaymentDetailsByDateRange(searchStartDate,searchEndDate);
              getMissingPaymentDetailsByDateRange(searchStartDate,searchEndDate);
            }}>Search</Button>
          </Box>
       
        
        <br></br>
      
      <Box
        sx={{
          height: 550,
          width: '80%',
          marginLeft:"10%",
          marginRight:"10%",
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
      <DataGrid rows={preProcess(reportSummary,missingPaymentsReportSummary)} columns={columns}
        getRowId={(row) => row.paymentId + ' ' + row.id}
        slots={{
          pagination: CustomPagination,
        }}
        // onFilterModelChange={handleFilterChange}
        getRowClassName={(params) =>
          params.row.receiptNumberStatus=="duplicate" ? classes.duplicateRow : 
          params.row.receiptNumberStatus=="no-entry" ? classes.noEntryRow : 
          params.row.receiptNumberStatus=="wrong-number" ? classes.WrongNumberRow : 
          params.row.receiptNumberStatus=="refunded" ? classes.refundedRow : classes.normalRow
      }
      sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        />
        </Box>
        {makePaymentModal && <MakePayment value={{paymentDetails:selectedRow}}/>}
        {missingReceiptWarning && <MissingReceiptNumberWarning/>}
        </Paper>
      </>
    );


}
export default RoomPaymentReport;