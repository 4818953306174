import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert,
    Grid,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
    Dialog

} from "@mui/material"
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Header from "../Header";
import {
    GridPagination,
    DataGrid,
    getGridStringOperators
  } from '@mui/x-data-grid';
import { NumericFormat,PatternFormat } from "react-number-format";
import { dateDifference, formatDate, formatDateText } from "../CommonFunctions";
import { parseISO,eachDayOfInterval, format } from "date-fns";
import { Link } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import MakePayment from "../MakePayment";

// Define your styles
const useStyles = makeStyles((theme) => ({
  duplicateRow: {
        backgroundColor: '#ffcccc',
        '&:hover': {
            backgroundColor: '#c99393 !important' ,  // Change to desired hover color
        },
  
    },
    noEntryRow: {
      backgroundColor: '#b6e6fa',
      '&:hover': {
          backgroundColor: '#8ed6f5 !important' ,  // Change to desired hover color
      },
    },

      WrongNumberRow: {
        backgroundColor: '#f5ecab',
        '&:hover': {
            backgroundColor: '#eddd66 !important' ,  // Change to desired hover color
        },

  },
  refundedRow: {
    backgroundColor: '#b3db97',
    '&:hover': {
        backgroundColor: '#9adb6b !important' ,  // Change to desired hover color
    },

},
}));

const dotStyles= (colorCode) =>{
  return {
  height: '12px',
  width: '12px',
  borderRadius: '50%',
  display: 'inline-block',
  backgroundColor:`${colorCode}`
  }
}

let last12Months=[];
const options = { month: 'short', day: 'numeric', year:'numeric' };

const now=new Date();
now.setDate(1);
const TaxMonthlyReport=()=>{
  const currentDate=formatDate(new Date());

  const [searchStartDate,setSearchStartDate]=  useState(formatDate(now));
  const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date()));
  const [reportSummary,setReportSummary] = useState([])
  const [occupancyReport,setOccupancyReport] = useState([]);
  const [last12Months,setLast12Months] = useState([]);
  const [missingPaymentsReportSummary,setMissingPaymentsReportSummary] = useState([]);
  const [makePaymentModal,setMakePaymentModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [sortModel, setSortModel] = useState([]);
  const [missingReceiptWarning, setMissingReceiptWarning] = useState(false);
  let totalExemptAmount=0,totalTaxableAmount=0,totalSalesTax=0,totalTourismTax=0;
  let salesTax=0.07;
  let tourismTax=0.05;
  const roomsToTotalsDict={};
 
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      console.log(parseISO(searchStartDate).toLocaleDateString('en-us',options))
        
    }, [searchStartDate,searchEndDate]);
    useEffect(() => {
      getLast12Months();
    }, [currentDate]);
    
    function getLast12Months() {
      const months = [];
      const currentDate = new Date();
      currentDate.setDate(1); // Set to the first day of the current month
  
      for (let i = 0; i < 12; i++) {
          const startDate = new Date(currentDate);
          let endDate = new Date(currentDate);
          if(i!==0){
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0); // Set to the last day of the previous month
          }
          else{
            endDate=new Date();
          }
          
  
          months.push({
              startDate: new Date(startDate),
              endDate: new Date(endDate),
          });
  
          // Move to the previous month
          currentDate.setMonth(currentDate.getMonth() - 1);
      }
      console.log(months)
      setLast12Months(months)
  }
  const columns=[
    { field: 'roomid', headerName: 'Room Number', width: 80, align: 'left',
      // filterOperators: getGridStringOperators().filter(
      //   (operator) => operator.value === 'equals' 
      // )
    },
    { field: 'paymentId', headerName: 'Payment Id', width: 100, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        if(currentRow.paymentId)
        return currentRow.paymentId
      return "No Payment";
      }
     },
    { field: 'receiptNumber', headerName: 'Receipt Number', width: 120, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
          return <Link 
          onMouseUp={()=>{{setMakePaymentModal(false)}}}
                onTouchMove={()=>{{setMakePaymentModal(false)}}}
          onClick={(e)=>{
            setMakePaymentModal(true);
            setSelectedRow(params.row);

          }}>
            {currentRow.receiptNumber}
          </Link>
      }
     },
     { field: 'warning', headerName: 'Alerts', width: 80, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        if(currentRow.receiptNumber){
        if(currentRow.receiptNumber.includes("Warning"))
          return 
          <WarningTwoToneIcon onClick={(e)=>{
            setMissingReceiptWarning(true);
          }}/>
        return <></>
      }
      }
      
     },
     { field: 'paidBy', headerName: 'Paid By', width: 180, align: 'left',
    },
    { field: 'actualPaymentDate', headerName: 'Payment Date', width: 140, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        if(currentRow.actualPaymentDate)
        return parseISO(currentRow.actualPaymentDate).toLocaleDateString('en-us',options)
      return null;
      }
     },
    { field: 'checkin', headerName: 'Tenancy Period', width: 220, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        // if(currentRow.paymentId)
        return <Link to={`/tenants/add/${currentRow.id}`} target="_blank">
        {parseISO(currentRow.checkin).toLocaleDateString('en-us',options)
        + '  -  ' +
        parseISO(currentRow.checkout).toLocaleDateString('en-us',options)
        }
        </Link>
        // else{
        //   return <Link>{parseISO(currentRow.checkin).toLocaleDateString('en-us',options)
        //     + '  -  ' +
        //     parseISO(currentRow.checkout).toLocaleDateString('en-us',options)
        //     }</Link>
        // }
        
       }
    },
    { field: 'rate', headerName: 'Rent', width: 90, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.rate} thousandSeparator prefix={'$'} displayType="text"  style={{color:"grey"}}/>
        </>
        
       }
     },
     { field: 'amount', headerName: 'Total', width: 90, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <b>
        <NumericFormat value={currentRow.amount} thousandSeparator prefix={'$'} displayType="text"/>
        </b>
        
       }
     },

    { field: 'cashAmount', headerName: 'Cash Amount', width: 90, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.cashAmount} thousandSeparator prefix={'$'} displayType="text" style={{color:"grey"}}/>
        </>
        
       }
     },

     { field: 'creditCardAmount', headerName: 'Credit Card Amount', width: 90, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.creditCardAmount} thousandSeparator prefix={'$'} displayType="text"  style={{color:"grey"}}/>
        </>
        
       }
     },

     { field: 'otherAmount', headerName: 'Other', width: 90, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.otherAmount} thousandSeparator prefix={'$'} displayType="text"  style={{color:"grey"}}/>
        </>
        
       }
     },

     
     { field: 'difference', headerName: 'Difference', width: 90, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.difference} 
        thousandSeparator prefix={'$'} displayType="text"/>
        </>
        
       }
     },
    { field: 'firstArrivalDate', headerName: 'Initial Checkin Date', width: 150, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return parseISO(currentRow.firstArrivalDate).toLocaleDateString('en-us',options)
      }
     },
    { field: 'numberOfDaysStayed', headerName: 'Number of Days stayed', width: 80, align: 'left' },
    { field: 'taxableAmount', headerName: 'Taxable Amount', width: 100, align: 'left',
      renderCell:(params)=>{
      const currentRow=params.row;
      return <>
      <NumericFormat value={currentRow.taxableAmount} thousandSeparator prefix={'$'} displayType="text"/>
      </>
      
     } },
    { field: 'exemptAmount', headerName: 'Exempt Amount', width: 120, align: 'left', 
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.exemptAmount} thousandSeparator prefix={'$'} displayType="text"/>
        </>
        
       }
    },

    { field: 'salesTax', headerName: 'Sales Tax', width: 110, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.salesTax} thousandSeparator prefix={'$'} displayType="text"/>
        </>
        
       }
     },
    { field: 'tourismTax', headerName: 'Tourism Tax', width: 100, align: 'left',
      renderCell:(params)=>{
        const currentRow=params.row;
        return <>
        <NumericFormat value={currentRow.tourismTax} thousandSeparator prefix={'$'} displayType="text"/>
        </>
        
       }
    },
    
    

  ]
  const getPaymentDetailsByDateRange = async(searchStartDate,searchEndDate) =>{
    const dateColumnFilter = 'actualPaymentDate';
    await axios.post(`${url}/payments/allPaymentsinDateRange`,{
        searchStartDate,
        searchEndDate,
        dateColumnFilter
      }).then(async(responseForTenancyDetails)=>{
        console.log(responseForTenancyDetails.data)
        if(responseForTenancyDetails && responseForTenancyDetails.data)
        setReportSummary(responseForTenancyDetails.data)
       
      })
  }
  const getMissingPaymentDetailsByDateRange = async(searchStartDate,searchEndDate) =>{
    const dateColumnFilter = 'actualPaymentDate';
    await axios.post(`${url}/getMissingPayments`,{
        searchStartDate,
        searchEndDate,
        dateColumnFilter
      }).then(async(responseForTenancyDetails)=>{
        console.log(responseForTenancyDetails.data)
        if(responseForTenancyDetails && responseForTenancyDetails.data)
        setMissingPaymentsReportSummary(responseForTenancyDetails.data)
       
      })
    }
  
    const preProcess = (data,missingData)=>{
    let resultArray=[];
    let receiptNumberDict={};
    let rangeDict={};
    let minValue=null, maxValue=null;
    for(let itr in data){
      const receiptNumber=data[itr].receiptNumber;
      if(receiptNumber){
        if(receiptNumber in receiptNumberDict){
          receiptNumberDict[receiptNumber]+=1;

        }
        else{
          receiptNumberDict[receiptNumber]=1;
        }
        rangeDict[receiptNumber]=1;
        let strValue=receiptNumber.toString();
        if(strValue.length===6){
          if(minValue==null){
            minValue=receiptNumber;
            maxValue=receiptNumber;
          }
          else{
            
            if(minValue>receiptNumber){
              minValue=receiptNumber;
            }
            if(maxValue<receiptNumber){
              maxValue= receiptNumber;
            }
          }
      }

      }
    }
    
    
    
    for(let itr in data){
        const currentRow=data[itr];
        let tempJson=data[itr];
        let amount=0;
        if(currentRow.cashAmount)
            amount=amount+parseInt(currentRow.cashAmount);
        if(currentRow.creditCardAmount)
            amount+=parseInt(currentRow.creditCardAmount);
        if(currentRow.otherAmount)
            amount+=parseInt(currentRow.otherAmount);
        tempJson["amount"]=amount;
        
        tempJson["numberOfDaysStayed"] = dateDifference(currentRow.checkin,currentRow.firstArrivalDate) +1;
        const dateDiff_checkin=dateDifference(currentRow.firstArrivalDate,currentRow.checkin);
        const dateDiff_checkout=dateDifference(currentRow.firstArrivalDate,currentRow.checkout);
        //Tenenacy period less than threshold
        if(dateDiff_checkin<=183 && dateDiff_checkout<=183){
          tempJson["taxableAmount"] = Math.round((amount/(1+salesTax+tourismTax)) * 100) / 100;
          tempJson["exemptAmount"] = 0

        }

        else if(dateDiff_checkin>183 && dateDiff_checkout>183){
          tempJson["taxableAmount"] = 0;
          tempJson["exemptAmount"] = amount;

        }
        
        else{
          const ndays=dateDifference(currentRow.checkin,currentRow.checkout);
          const init=183-dateDiff_checkin;
          const amo=(amount/ndays)*init;
          tempJson["taxableAmount"] = Math.round((amo/(1+salesTax+tourismTax)) * 100) / 100;
          tempJson["exemptAmount"] = Math.round(((amount/ndays)*(ndays-init)) * 100) / 100;;
          

        }
        tempJson["salesTax"]= Math.round((tempJson["taxableAmount"] * 0.07) * 100) / 100;
        tempJson["tourismTax"]= Math.round((tempJson["taxableAmount"] * 0.05) * 100) / 100;
        totalTaxableAmount+=tempJson["taxableAmount"];
        totalExemptAmount+=tempJson["exemptAmount"];
        totalSalesTax+=tempJson["salesTax"]
        totalTourismTax+=tempJson["tourismTax"];

      const receiptNumber=currentRow.receiptNumber;
      
      if(receiptNumber){
        if(receiptNumber in receiptNumberDict && receiptNumberDict[receiptNumber]>1){
        tempJson["receiptNumberStatus"]="duplicate"

        }
        else{
        tempJson["receiptNumberStatus"]="all-okay"
        if(amount<0){
          tempJson["receiptNumberStatus"]="refunded"
  
        }

        }
        let strValue=receiptNumber.toString();
        if(strValue.length!=6){
        tempJson["receiptNumberStatus"]="wrong-number"

        }
      }
      else{
        tempJson["receiptNumberStatus"]="no-entry"
      }
      tempJson["difference"]=currentRow.rate-amount;


      if(!(currentRow.roomid in roomsToTotalsDict)){
        roomsToTotalsDict[currentRow.roomid]={
          total:0,
          taxableAmount:0,
          exemptAmount:0,
          salesTax:0,
          tourismTax:0
        }

      }
      roomsToTotalsDict[currentRow.roomid].total+=amount;
      roomsToTotalsDict[currentRow.roomid].taxableAmount+=tempJson["taxableAmount"];
      roomsToTotalsDict[currentRow.roomid].exemptAmount+=tempJson["exemptAmount"];
      roomsToTotalsDict[currentRow.roomid].salesTax+=tempJson["salesTax"];
      roomsToTotalsDict[currentRow.roomid].tourismTax+=tempJson["tourismTax"];


        resultArray.push(tempJson);

    }
    for(let itr in missingData){
      let tempJson=missingData[itr];
      tempJson["difference"]=missingData[itr].rate-( missingData[itr].amount ? missingData[itr].amount : 0);
      resultArray.push(tempJson);
    }

    // if(minValue!=null){
    //   for(let itr=minValue; itr<=maxValue;itr++){
    //     if(itr in rangeDict){
    //       continue;
    //     }
    //     else{
    //       let tempJson={paymentId:-1*itr,receiptNumber:itr,receiptNumberStatus:"missing"};
    //       resultArray.push(tempJson)
    //     }
    //   }
    // }
    console.log("Roomwise statistics");
    console.log(roomsToTotalsDict)
    return resultArray;

  }
  // Function to check for the first missing receipt number
  const findFirstMissingReceiptNumber = (sortedRows) => {
    for (let i = 1; i < sortedRows.length; i++) {
      const prevReceipt = sortedRows[i - 1].receiptNumber;
      const currentReceipt = sortedRows[i].receiptNumber;

      if (currentReceipt - prevReceipt > 1) {
        return parseInt(prevReceipt) + 1; // Return the first missing receipt number
      }
    }
    return null;
  };

  // Handle sort model change
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);

    // Check if the receiptNumber column is sorted
    const isReceiptNumberSorted = newSortModel.some(
      (model) => model.field === 'receiptNumber'
    );
    const isAscending = newSortModel.some(
      (model) =>  model.sort === 'asc'
    );

    if (isReceiptNumberSorted) {
      // Sort the rows by receipt number
      if(isAscending){
      const sortedRows = [...reportSummary].sort((a, b) => a.receiptNumber - b.receiptNumber);
      const missingReceipt = findFirstMissingReceiptNumber(sortedRows);

      if (missingReceipt) {
        // Add a row indicating a missing receipt number if there's a gap
        setReportSummary((prevRows) => [
          ...prevRows,
          {
            id: prevRows.length + 1,
            name: 'Missing Receipt Number',
            receiptNumber: `${missingReceipt} (Warning)`, //Hide the receipt number while
          },
        ]);
      }
    }
    } else {
      // Remove the "missing" row if sorting a different column
      setReportSummary((prevRows) =>
        prevRows.filter((row) => !row.receiptNumber.toString().includes('Warning'))
      );
    }
  };
  const MissingReceiptNumberWarning=()=>{
    return <>
    <React.Fragment>
      <Button variant="outlined" onClick={(e)=>{
        setMissingReceiptWarning(false)
      }}>
        
      </Button>
      <Dialog
        open={missingReceiptWarning}
        onClose={(e)=>{
          setMissingReceiptWarning(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Missing Receipt Number
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
    </>
}
  function CustomPagination(props) {
    return <>
    <b>Taxable Sales
    <NumericFormat value={Math.round((totalTaxableAmount+totalExemptAmount) * 100) / 100} thousandSeparator prefix={'$'} displayType="text"/></b>
    <b>Total Exempt Amount
    <NumericFormat value={Math.round((totalExemptAmount) * 100) / 100} thousandSeparator prefix={'$'} displayType="text"/></b>
        <b>Total Taxable Amount
        <NumericFormat value={Math.round((totalTaxableAmount) * 100) / 100} thousandSeparator prefix={'$'} displayType="text"/></b>
        <b>Total Sales Tax(7%) 
          {/* FIX ME SALES TAX  PERCENTAGE */}
        <NumericFormat value={Math.round((totalSalesTax) * 100) / 100} thousandSeparator prefix={'$'} displayType="text"/></b>
        <b>Total Tourism Tax({tourismTax*100}%)
    <NumericFormat value={Math.round((totalTourismTax) * 100) / 100} thousandSeparator prefix={'$'} displayType="text"/></b>  
        
        <GridPagination  {...props} /> </>;
  }
  // const handleFilterChange=(newFilter)=>{
  //   const items=newFilter.items;
  //   const filtered=items.find(item => item.field === 'roomid');
  //   const currentRow=filtered[0];
  //   if(currentRow && currentRow.value){
  //     const roomNumber = Number(currentRow.value);
  //     console.log("Room Number selected: " + roomNumber);
  //   }

  // }
  
  
  
    
    
  const classes = useStyles();

    return (
      <>
      <Header/>
      <Box sx={{  padding:"0% 5%",backgroundColor:"rgb(250, 250, 251)" }}>
        <br></br>
        <Grid container spacing={2}>
        
        
        <Grid item xs={12} sm={12} md={2}> <span style={dotStyles('#c99393')}/> &nbsp;<b><i>Duplicate records</i></b> </Grid>
        <Grid item xs={12} sm={12} md={2.5}> <span style={dotStyles('#8ed6f5')}/> &nbsp;<b><i>Records with missing receipt number</i></b></Grid>
        <Grid item xs={12} sm={12} md={2.5}> <span style={dotStyles('#eddd66')}/> &nbsp;<b><i>Records with wrong receipt number</i></b> </Grid>
        <Grid item xs={12} sm={12} md={2}> <span style={dotStyles('#9adb6b')}/> &nbsp;<b><i>Refunded records</i></b> </Grid>
        
        <Grid item xs={12} sm={12} md={3}>
        <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          onChange={(e)=>{
            setSearchStartDate(formatDate(e.target.value.startDate))
            setSearchEndDate(formatDate(e.target.value.endDate));
            getPaymentDetailsByDateRange(formatDate(e.target.value.startDate),formatDate(e.target.value.endDate));
            getMissingPaymentDetailsByDateRange(formatDate(e.target.value.startDate),formatDate(e.target.value.endDate));
          }}
        >
          {last12Months.map((month)=>(
             <MenuItem value={month}>{month.startDate.toLocaleDateString('en-US', options) + " - " + 
              month.endDate.toLocaleDateString('en-US', options)}</MenuItem>
          ))}
          </Select>
          </FormControl>
        </Grid>
      </Grid>
      </Box>
      <Box
        sx={{
          height: 550,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
      <DataGrid rows={preProcess(reportSummary,missingPaymentsReportSummary)} columns={columns}
        getRowId={(row) => row.paymentId + ' ' + row.id}
        slots={{
          pagination: CustomPagination,
        }}
        // onFilterModelChange={handleFilterChange}
        getRowClassName={(params) =>
          params.row.receiptNumberStatus=="duplicate" ? classes.duplicateRow : 
          params.row.receiptNumberStatus=="no-entry" ? classes.noEntryRow : 
          params.row.receiptNumberStatus=="wrong-number" ? classes.WrongNumberRow : 
          params.row.receiptNumberStatus=="refunded" ? classes.refundedRow : classes.normalRow
      }
      sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        />
        </Box>
        {makePaymentModal && <MakePayment value={{paymentDetails:selectedRow}}/>}
        {missingReceiptWarning && <MissingReceiptNumberWarning/>}
      
      </>
    );


}
export default TaxMonthlyReport;