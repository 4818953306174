import React from "react";
import { useState } from "react";
import { 
    Paper, Grid, Container, 
    Table, TableContainer, TableBody, TableCell, TableHead ,TableRow, Button, ButtonGroup,    
    Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField,
    Stack} from "@mui/material"
import { styled } from '@mui/material/styles';
import Header from "./Header"
import WeekendIcon from '@mui/icons-material/Weekend';
import WeekendOutlinedIcon from '@mui/icons-material/WeekendOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    fontSize:"1rem",
    fontFamily:"sans-serif",
    textAlign: 'left',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
     
    }),
  }));
  const DialogPopUp= ({displayPopUp, setDisplayPopUp})=>{
    // const [open,setOpen] = useState(true);
    function handleClose(){
      setDisplayPopUp(false)
    }
    return <>
    <Dialog
        open={displayPopUp}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions>
      </Dialog>
    </>
  }
 const  TenancyV2= () => {
  const [displayPopUp, setDisplayPopUp] = useState(false);
    const rows = [
        createData('saranya', '(470)-836-2073', 'sp@gmail.com'),
        createData('saranya', '(470)-836-2073', 'sp@gmail.com'),

        
      ];
      const buttons = ['Checkin', 'Save', 'Checkout', 'Renew', 'Payment', 'Void'];
      function createData(
        name: string,
        phone: string,
        email: string
      ) {
        return { name, phone, email};
      }
      
    return <>
    <Header/>
    <br></br>
    <Paper elevation={3} style={{backgroundColor:"grey",height:"37rem"}}>
    <Container fixed>
    <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={8}>
    <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={6}>
      <Paper elevation={4} >
        <Item>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                    <b style={{fontSize:"1.8rem",color:"blue"}}> 18 </b>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <WeekendOutlinedIcon/> Booking Id - 123 <br></br> 
                    <WeekendOutlinedIcon/> Vacant
                    
                </Grid>
            </Grid>
        </Item>
    </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
       <Paper elevation={4} >
         <Item onClick={(e)=>{
          setDisplayPopUp(true)
         }}>
             <Grid container spacing={2}>
                
                 <Grid item xs={12} sm={12} md={8}>
                    <CalendarTodayOutlinedIcon/> Checkin - Nov 5th, 2024 <br></br> 
                    <CalendarTodayOutlinedIcon/> Checkout - Nov 5th, 2024
                    
                 </Grid>
                 <Grid item xs={12} sm={12} md={4}>
                     <b style={{fontSize:"1.8rem",color:"blue"}}> 7 days </b>
                 </Grid>
             </Grid>
         </Item>
     </Paper>
      </Grid>
    </Grid>
    <br></br>
    <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12}>
    <Paper elevation={4} >
        <Item>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                     Rent - $400 <br></br> 
                     Rental Type- Weekly <br></br> 
                     Deposit - $100 <br></br> 
                     Paid - $450 <br></br> 
                     Balance - $50
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <span> 
                        <InsertDriveFileOutlinedIcon/> Checkin form<br></br>
                        <InsertDriveFileOutlinedIcon/> 3 days Notice<br></br>
                        <InsertDriveFileOutlinedIcon/> 7 days Notice
                        </span>
                </Grid>
            </Grid>
        </Item>
    </Paper>
      </Grid>
    </Grid>
    <br></br>
    <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12}>
    <Paper elevation={4} >
        <Item>
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650}} aria-label="simple table">
            <TableHead sx={{fontSize:"1.2rem",fontWeight:"bolder" }}>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Email</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.phone}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
        </TableContainer>
        </Item>
    </Paper>
      </Grid>
    </Grid>

    
    </Grid>
    <Grid item xs={12} sm={12} md={2} style={{}}>
    <Paper elevation={4} >
        <Item>
        <ButtonGroup
        orientation="vertical"
        aria-label="Vertical button group"
        variant="contained"
        
      >
        {buttons.map((button, index) => (
        <Box key={index} mb={index < buttons.length  ? 2 : 0} >
      <Button variant="contained" startIcon={<DeleteIcon />}>{button}</Button> 
        </Box>
      ))}
      </ButtonGroup>
      
        
        </Item>
    </Paper>
    
    </Grid>

  </Grid>
  </Container>
  </Paper>
  {displayPopUp && <DialogPopUp displayPopUp={displayPopUp} setDisplayPopUp={setDisplayPopUp} />}
    </>
}
export default TenancyV2



  