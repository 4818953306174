import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle, useRef } from "react";
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $, { fn } from 'jquery';
import { Link, redirect } from "react-router-dom";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {Provider} from "./GuestContext";
import SearchDateContext from "./GuestContext";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import Table from 'react-bootstrap/Table';
import { useFormInput } from './useFormInput.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {formatDate, formatDateText, parseResponseJSON} from "./CommonFunctions";
import { ManageCustomerInformationComponent } from "./Customer";
import TestComponent from "./TestComponent";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { parseISO } from "date-fns";

// Register fonts with pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const options = { weekday: 'short', month: 'short', day: 'numeric', year:'numeric' };
export const CheckinForm=(props)=>{
    const [show,setShow]=useState("");
    let today=formatDate(new Date());
    today=parseISO(today).toLocaleDateString('en-us',options);
    console.log("today : " + typeof(today))
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
        setShow(props.value.isCheckinFormVisible)
        console.log(props.value.tenancyDetails)
       },[props]);
    const handleClose=()=>{
        setShow(false);
        props.setIsCheckinFormVisible(false)
    }
    function printPage(){
        const contents=[];
        const terms=[];
        terms.push('This is an extended stay motel and stay is temporary. This is not your primary residence. You are a transient occupant.\n');
        terms.push('You can not use the motel address as your personal or business address.\n');
        terms.push('Check-out time is at 11:00 A.M.\n');
        terms.push('If you would like to occupy the premises for longer, you must speak with someone at the front desk and come to a written agreement. If no agreement is reached, you must vacate by the check-out time and date.\n');
        terms.push('Guests will be held responsible for any loss or damage done to a room during their stay.\n');
        terms.push('Motel Management is not responsible for your personal belongings or valuables.\n');
        terms.push('We check toilets for clogging issues before your stay. If the toilet is clogged during your stay, a sum of $250 per plumber call will be charged. Guest will be responsible for any additional charges if applicable\n');
        terms.push('The room you are assigned MUST be left in the same condition in which you rented it.\n');
        terms.push('No tampering with or removal of smoke detectors.\n');
        terms.push('Speed Limit 5 MPH. Careful driving is expected \n');
       const termsContent=[];
        for (let i = 0; i < terms.length; i++) {
            termsContent.push({ text: terms[i], style: 'listItem' });
          }

        const rules=[];
        rules.push('No Pets. If any pets are found, the tenant agrees to pay a fine of $500 and move out immediately. If damages are more than $500, the tenant agrees to pay the attorney and legal fees.');
        rules.push('No smoking allowed in the rooms. You will be asked to leave immediately and legal action will follow if we find you smoking inside the roomQuiet hours are from 9pm - 6am');
        rules.push('Cleanliness : We appreciate you keeping the rooms clean. We only allow clean tenants. You will be requested to leave if you keep the room dirty. Management reserves the right to decide.');
        rules.push('No loud music');
        rules.push('No loud arguments. Our guests like our place quiet. You will be asked to leave if you argue loud');
        rules.push('No additional guests / human trafficking / drugs. If we find traffic to the room, you will be asked to leave immediately. We work with police to stop human trafficking and drugs. We alert the police immediately and you will be reported as a potential drug dealer or human trafficker. You agree to leave if any such incidents happen');
        rules.push('Zero tolerance for slamming the doors. You will be asked to leave immediately and legal action may follow if there are damages');
        rules.push('Courtesy: Please be courteous to the employees. We can discuss and resolve problems without raising the voice.');
        rules.push('Inspections. Management will do inspections for smoking, cleanliness, presence of pets or any safety or operational concerns..');
            
        const rulesContent=[];
        for (let i = 0; i < rules.length; i++) {
            rulesContent.push({ text: rules[i], style: 'listItem' });
          }
        var nameString="";

        props.value.customersDetails.map((c, index) => {
            if(index==0)
            nameString=c.fname + " " + c.lname;
            else
            nameString=nameString+ " , " + c.fname + " " + c.lname;
            
            
        })
        const documentDefinition = {
            content: [
              {
                text: 'Tidy Inn LLC\n1601 George Jenkins Blvd\nLakeland Fl. 3381\n863-825-5007 \n',
                style: 'header'
              },
              
              {
                text: '',
                style: 'subheader'
              },
              {
                margin: [20,0,0,10],
                fontSize: 11,
                text:[
                    { text: 'Name(s) : ', style: 'inputLabel' }, // Text before the bold word
                    { text: nameString + '\n', bold: true }, // Bold word
                ] 
              },
              {
                alignment: 'justify',
                margin: [20,0,0,10],
                fontSize: 11,
                columns: [
                    {
                        text: [
                            { text: ' Phone Number : ', style: 'inputLabel'  }, // Text before the bold word
                            { text: props.value.customersDetails[0].phone + '\n', bold: true }, // Bold word
                        ]
                    },
                    {
                        text:[
                            { text: ' Email Address : ', style: 'inputLabel'  }, // Text before the bold word
                            { text: props.value.customersDetails[0].email + '\n', bold: true }, // Bold word
                        ]
                    }

                ]

              },

              {
                alignment: 'justify',
                margin: [20,0,0,10],
                fontSize: 11,
                lineHeight: 1.7,

                columns: [
                    {
                        text: [
                            { text: 'Checkin Date : ', style: 'inputLabel'  }, // Text before the bold word
                            { text: parseISO(props.value.tenancyDetails.checkin).toLocaleDateString('en-us',options) + '\n', bold: true }, // Bold word
                            { text: 'Rate : $', style: 'inputLabel'  }, // Text before the bold word
                            { text: props.value.tenancyDetails.rate + ' (' + props.value.tenancyDetails.rentalType + ')' + '\n', bold: true },
                            { text: 'Number Of Adults # : ', style: 'inputLabel'  }, // Text before the bold word
                            { text: props.value.tenancyDetails.adults + '\n', bold: true },
                            { text: 'Expected Checkout Date : ', style: 'inputLabel'  }, // Text after the bold word
                            { text: parseISO(props.value.tenancyDetails.checkout).toLocaleDateString('en-us',options) + '\n', bold: true },

                        ]

                    },
                    {
                        text: [
                            { text: 'Room # : ' , style: 'inputLabel' }, // Text after the bold word
                            { text: props.value.tenancyDetails.roomid + '\n', bold: true },
                            { text: 'Room Deposit : $' , style: 'inputLabel' }, // Text after the bold word
                            { text: props.value.tenancyDetails.deposit + '\n', bold: true },
                            { text: 'Number Of Children # : ', style: 'inputLabel'  }, // Text after the bold word
                            { text: props.value.tenancyDetails.children + '\n\n', bold: true },

                        ]

                    }
                
                ]
                },
           
              {
                text: 'Terms and Conditions \n',
                style: 'header'
              },
              {
                ol:termsContent,
                style:' listItems'

              },
              {
                text:'By signing you acknowledge you have read all of the above terms and conditions\n\n',
                style: 'statement'
              },
              {
                text:'Guest Signature............................................\t\t\tManager Signature......................................\n\n',
                style: 'statement'

              },
              {
                text:'Date: '+ today +'\n\n\n\n\n\n\n',
                style: 'statement'
              },
              {
                text:'\nTIDY INN HOUSE RULES \n\n',
                style:"header"

              },

              {
                ul: rulesContent,
                style: 'listItems'
              },
              {
                text:'\nI agree with the house rules. I agree to check out immediately if management decides that rules are broken. I will be responsible for any damages / court charges caused\n\n',
                style: 'statement'
              },
              {
                text:'Guest Signature........................................\t\t\tManager Signature......................................\n\n',
                style: 'statement'
              },
              {
                text:'Date: '+ today +"\n\n\n\n",
                style: 'statement'
              },
              {
                text: '\n\n\n\n\n\n\n\n\n\nTidy Inn LLC\n1601 George Jenkins Blvd\nLakeland Fl. 3381\n863-825-5007 \n',
                style: 'header'
              },
              {
                text: 'Room Number #: ' + props.value.tenancyDetails.roomid + '\n\n'

              },
              {
                margin: [20,0,0,10],
                fontSize: 11,
                text:[
                    { text: 'Name(s) : ' }, // Text before the bold word
                    { text: nameString + '\n\n', bold: true }, // Bold word
                ] 
              },
              {
                bold: false,
                text:'I understand and agree that this will not be my primary residence. I have a permanent address somewhere else and agree and understand that this will be a transient occupancy.\n\n',
                style: 'statement'
              },
              {
                bold: false, 
                text:'I agree to check out on ' + props.value.tenancyDetails.checkout +' before 11:00 am EST. I will remove all my stuff, leave the room in a clean condition, and return the keys to the manager at the front desk as well.',
                style:'statement'

              },
              {
                text:'\n\nGuest Signature.............................................\t\t\tManager Signature.......................................\n\n',
                style: 'statement'
              },
              {
                text:'Date: '+ today +"\n\n",
                style: 'statement'
              }
            ],
            styles: {
              header: {
                fontSize: 12,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 20]
              },
              subheader: {
                fontSize: 11,
                bold: true,
                margin: [0, 0, 0, 10]
              },
              listItem: {
                fontSize: 10,
                margin: [10,0,10,7],
                alignment: 'left',
                lineHeight: 1.5,
                // marginLeft: 10,
                // marginRight: 10,
                // marginBottom: 5,
                bold:false
              },
              statement: {
                fontSize: 10,
                margin: [0,0,10,7],
                alignment: 'left',
                lineHeight: 1.5,
                bold: true
              },
              inputLabel: {
                fontSize: 10,
                bold: true,
                margin: [0, 0, 0, 20],
                color:"grey"

              },
            },
            
            
          };
      
          // Create the PDF
          const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
          pdfDocGenerator.download('checkin-'+ props.value.tenancyDetails.checkin + '.pdf');
         
        
  //     doc.setFont('helvetica', 'bold');

  // // Set font size
  // doc.setFontSize(12);

  // // Set text color (RGB)
  // doc.setTextColor("black");

  // // Add styled text to the PDF
  // doc.text('Tidy Inn LLC\n1601 George Jenkins Blvd\nLakeland Fl. 3381\n863-825-5007 \n', 70, 20);
  // doc.setFontSize(11);
  // doc.setFont('helvetica', 'normal');
  // var nameString="";

  // props.value.customersDetails.map((c, index) => {
  //   if(index==0)
  //   nameString=c.fname + " " + c.lname;
  //   else
  //   nameString=nameString+ " , " + c.fname + " " + c.lname;
    
    
  // })
  // doc.text("Guest Name(s): " + nameString + " \n" , 20 , 50);
  // doc.text("Phone Number: " + props.value.customersDetails[0].phone + "\t \t Email Address: " + props.value.customersDetails[0].phone + "\n" , 20 , 55);
  // doc.text("Arrival Date: " + props.value.tenancyDetails.arrival + "\t\t\tRoom#: " + props.value.tenancyDetails.roomid +"\n" , 20 , 60);
  // doc.text("Rate: " + props.value.tenancyDetails.rate + " ( " + props.value.tenancyDetails.rentalType + ")\t\t\tRoom Deposit: " + props.value.tenancyDetails.deposit +"\n" , 20 , 65);
  // doc.text("Number of Adults: " + props.value.tenancyDetails.adults + "\t\t\tNumber of Children: : " + props.value.tenancyDetails.children +"\n" , 20 , 70);
  // doc.text("Check out date: "+props.value.tenancyDetails.checkout +"\n" , 20 , 75);
  // doc.setFont('helvetica', 'bold');
  // doc.text("Terms and Conditions \n" , 20 , 85);
  // doc.setFont('helvetica', 'normal');
  // doc.text("1. This is an extended stay motel and stay is temporary. This is not your primary residence. \n You are a transient occupant." , 30 , 100);
  // doc.text("2. You can not use the motel address as your personal or business address. \n" , 30 , 114);
  // doc.text("3. Check-out time is at 11:00 A.M. \n" , 30 , 121);
  // doc.text("4. If you would like to occupy the premises for longer, you must speak with someone at the front desk\n and come to a written agreement.  If no agreement is reached, \nyou must vacate by the check-out time and date. \n" , 30 , 128);
  // doc.text("5. Guests will be held responsible for any loss or damage done to a room during their stay. \n" , 30 , 142);
  // doc.text("6. Motel Management is not responsible for your personal belongings or valuables.  \n" , 30 , 149);
  // doc.text("7. We check toilets for clogging issues before your stay. If the toilet is clogged during your stay,\n a sum of $250 per plumber call will be charged. \nGuest will be responsible for any additional charges if applicable  \n" , 30 , 156);
  // doc.text("8. The room you are assigned MUST be left in the same condition in which you rented it. \n" , 30 , 170);
  // doc.text("9. No tampering with or removal of smoke detectors.  \n" , 30 , 177);
  // doc.text("10. Speed Limit 5 MPH. Careful driving is expected. \n" , 30 , 184);


  // doc.text("By signing you acknowledge you have read all of the above terms and conditions. \n" , 20 , 192);
  // doc.text("Guest Signature: ............................. \t\t\t\t Date: " +  formatDate(new Date()) + "\n", 20 , 200);

  
  // doc.save('styled_text_example.pdf');
    }

return (

    <div>
       
        <Modal show={show} dialogClassName="checkin-form-modal" onHide={handleClose}>
            <Modal.Header closeButton><Modal.Title>
                Print Checkin Form</Modal.Title>
                </Modal.Header>
        
        <Modal.Body>
        <div style={{textAlign:"center"}} >
        <a><strong>Tidy Inn LLC</strong></a><br></br>
        <a>1601 George Jenkins Blvd </a><br></br>
        <a>Lakeland Fl. 33815 </a><br></br>
        <a>863-825-5007 </a><br></br>
        </div>
        <div className="info">
            {props.value.customersDetails.map((c, index) => (
                <div>
                    <div> <a>Guest Name(s): <strong>{c.fname + " " + c.lname} </strong></a></div>
                <div><a>Phone Number: <strong>{c.phone}</strong>  </a><a>Email Address: <strong>{c.email}</strong></a></div>
                </div>
            ))}
        </div>
<div><a>Room#: <strong style={{fontSize:"2em"}}>{props.value.tenancyDetails.roomid}</strong></a> <a>Tenancy Type: <strong  style={{fontSize:"1.5em"}}>{"Transient"}</strong></a></div>
        <div>
          <a>Checkin: <strong>{parseISO(props.value.tenancyDetails.checkin).toLocaleDateString('en-us',options)}</strong>  </a>
          <a>Checkout : <strong>{parseISO(props.value.tenancyDetails.checkout).toLocaleDateString('en-us',options)}</strong></a>
        </div>

        <div>
          <a>Rate: <strong>
            ${props.value.tenancyDetails.rate} ({props.value.tenancyDetails.rentalType})
          </strong></a>
          <a>Room Deposit: <strong>${props.value.tenancyDetails.deposit}</strong> </a>
        </div>

        <div><a>Number of Adults: <strong>{props.value.tenancyDetails.adults}</strong>  </a><a>Number of Children: <strong>{props.value.tenancyDetails.children}</strong></a></div>
        {/* <div><a>Tenancy Type: <strong  style={{fontSize:"1.5em"}}>{"Transient"}</strong></a></div> */}

        <h3>Terms and Conditions</h3>
        <ol style={{marginLeft:"5%",marginRight:"7%",textAlign:"left"}}>
            <li>This is an extended stay motel and stay is temporary. This is not your primary residence.  You are a <strong>transient occupant.</strong> </li>
            <li>You can not use the motel address as your personal or business address. </li>
            <li>Check-out time is at 11:00 A.M. </li>
            <li>If you would like to occupy the premises for longer, you must speak with someone at the front desk and come to a written agreement.  If no agreement is reached, you must vacate by the check-out time and date.</li>
            <li>Guests will be held responsible for any loss or damage done to a room during their stay. </li>
            <li>Motel Management is not responsible for your personal belongings or valuables</li>
            <li>We check toilets for clogging issues before your stay. If the toilet is clogged during your stay, a sum of $250 per plumber call will be charged. Guest will be responsible for any additional charges if applicable </li>
            <li>The room you are assigned MUST be left in the same condition in which you rented it.</li>
            <li>No tampering with or removal of smoke detectors. </li>
            <li>Speed Limit 5 MPH. Careful driving is expected</li>
        </ol>
        <p>By signing you acknowledge you have read all of the above terms and conditions</p>
        <a><strong>Guest Signature: </strong></a><hr/>
        <a><strong>Date: {today}</strong></a><hr/>

        <h3>TIDY INN HOUSE RULES</h3><br></br>
        <ul style={{marginLeft:"5%",marginRight:"7%",textAlign:"left"}}>
            <li><strong>No Pets.</strong> If any pets are found, the tenant agrees to pay a fine of $500 and move out immediately. If damages are more than $500, the tenant agrees to pay the attorney and legal fees.</li>
            <li><strong>No smoking allowed in the rooms.</strong> You will be asked to leave immediately and legal action will follow if we find you smoking inside the roomQuiet hours are from 9pm - 6am</li>
            <li><strong>Cleanliness :</strong> We appreciate you keeping the rooms clean. We only allow clean tenants. You will be requested to leave if you keep the room dirty. Management reserves the right to decide.</li>
            <li><strong>No loud music</strong></li>
            <li><strong>No loud arguments.</strong> Our guests like our place quiet. You will be asked to leave if you argue loud</li>
            <li><strong>No additional guests / human trafficking / drugs.</strong> If we find traffic to the room, you will be asked to leave immediately. We work with police to stop human trafficking and drugs. We alert the police immediately and you will be reported as a potential drug dealer or human trafficker. You agree to leave if any such incidents happen</li>
            <li><strong>Zero tolerance for slamming the doors.</strong> You will be asked to leave immediately and legal action may follow if there are damages</li>
            <li><strong>Courtesy:</strong>  Please be courteous to the employees. We can discuss and resolve problems without raising the voice. </li>
            <li><strong>Inspections.</strong> Management will do inspections for smoking, cleanliness, presence of pets or any safety or operational concerns..</li>

        </ul>
        <p>I agree with the house rules. I agree to check out immediately if management decides that rules are broken. I will be responsible for any damages / court charges caused </p>
        <a><strong>Guest Signature: </strong></a><hr/>
        <a><strong>Date: {today}</strong></a><hr/>

        <div style={{textAlign:"center"}}>
        <a><strong>Tidy Inn LLC</strong></a><br></br>
        <a>1601 George Jenkins Blvd </a><br></br>
        <a>Lakeland Fl. 33815 </a><br></br>
        <a>863-825-5007 </a><br></br>
        </div>
        <a>Room#: <strong>{props.value.tenancyDetails.roomid}</strong></a>
        <div className="info">
            {props.value.customersDetails.map((c, index) => (
                <div>
                    <div> <a>Guest Name(s): <strong>{c.fname + " " + c.lname} </strong></a></div>
                </div>
            ))}
        </div>

        
       
<p>I understand and agree that this will not be my primary residence.  I have a permanent address somewhere else and agree and understand that this will be a transient occupancy.</p>

<p>I agree to check out on <strong>{props.value.tenancyDetails.checkout}</strong> before 11:00 am EST.  I will remove all my stuff, leave the room in a clean condition, and return the keys to the manager at the front desk as well.</p>


        <a><strong>Guest Signature: </strong></a><hr/>
        <a><strong>Date: {today}</strong></a><hr/>
        </Modal.Body>
    
        <Modal.Footer>
    
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
    
          <Button variant="primary" onClick={printPage}>
            Print
          </Button> 
    
        </Modal.Footer>
      </Modal>

  {/* <Link
                  to={`http://localhost:3000/tenants/edit/${id}`}
                  // className="button1 is-small is-info mr-2"
                >
                  Edit
                </Link> */}

{/* <div className="info">
    {customersDetails.map((c, index) => (
<div>
     <div> <a>Guest Name(s): <strong>{c.fname + " " + c.lname} </strong></a></div>
<div><a>Phone Number: <strong>{c.phone}</strong>  </a><a>Email Address: <strong>{c.email}</strong></a></div>
</div>

))}
</div> */}
{/* <div><a>Arrival Date: <strong>{arrival}</strong>  </a><a>Room#: <strong>{id}</strong></a></div>
<div><a>Rate: <strong>${rate} ({type})</strong>  </a><a>Room Deposit: <strong>${deposit}</strong> </a></div>
<div><a>Number of Adults: <strong>{adults}</strong>  </a><a>Number of Children: <strong>{children}</strong></a></div>
<div><a>Check out date: <strong>{checkout}</strong></a></div> */}
</div>




);
}