import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import 'reactjs-popup/dist/index.css'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import $, { fn } from 'jquery'
import { Link, redirect } from 'react-router-dom'
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faCreditCard,
  faEnvelope,
  faPhone,
  faBuilding,
  faFlag,
  faDollar,
} from '@fortawesome/free-solid-svg-icons'
import { Provider } from './GuestContext'
import SearchDateContext from './GuestContext'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
// import Table from 'react-bootstrap/Table';
import { useFormInput } from './useFormInput.js'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
  formatDate,
  parseResponseJSON,
  logMe,
  dateDifference,
  isDateOrDatetime,
  getDataSorted,
  formatDateText,
} from './CommonFunctions'
import { ManageCustomerInformationComponent } from './Customer'
import { SearchRoomsByDateComponent } from './SearchRoomsByDate'
import TestComponent from './TestComponent'
import { CheckinForm } from './CheckinForm'
import { CheckoutForm } from './CheckoutForm'
import MakePayment from './MakePayment'
import { Row, Col } from 'react-bootstrap'
import Header from './Header.js'
import { Table, Rate, RadioTile, IconButton, Divider } from 'rsuite'
import SendSMS from './SendSMS'
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline'
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline'
import CustomerReviewModal from './CustomerReviewModal'
import AddFollowUp from './AddFollowUp.js'
import TenantPaymentSummary from './TenantPaymentSummary.js'
import TenancyDetailsByLTTI from './TenancyDetailsByLTTI.js'

const { Column, HeaderCell, Cell } = Table
const rowKey = 'longTermTenancyId'

const AllTenants = () => {
  let [activeTenancyDetails, setActiveTenancyDetails] = useState([])
  const [sortColumn, setSortColumn] = useState('stayDuration')
  const [sortType, setSortType] = useState('asc')
  const [loading, setLoading] = useState(false)
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false)
  const [showSendMessageModal, setShowSendMessageModal] = useState(false)
  const [showPaymentSummary, setShowPaymentSummary]= useState(false)
  const [showFollowUpModal, setShowFollowUpModal] = useState(false)
  const [rowDataProp, setRowDataProp] = useState({})
  const [tenancyId, setTenancyId] = useState('')
  const [longTermTenancyId, setLongTermTenancyId] = useState(null)
  const [showReview, setShowReview] = useState(false)
  const [tenantId, setTenantId] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [paymentInformation, setPaymentInformation] = useState([])
  const url = process.env.REACT_APP_SERVER_URL
  let totalRent = 0
  const navigate = useNavigate()
  useEffect(() => {
    getActiveTenants()
  }, [])

  const propsForMakePayment = {
    // tenancyDetails,
    // customersDetails
    tenancyId,
  }
  const getActiveTenants = async (e) => {
    if (e) e.preventDefault()
    try {
      await axios
        .get(`${url}/activeTenants`)
        .then((responseForActiveTenants) => {
          console.log(responseForActiveTenants.data)
          setActiveTenancyDetails(responseForActiveTenants.data)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setSortColumn(sortColumn)
      setSortType(sortType)
    }, 500)
  }
  function countOverstayedGuests(activeTenancyDetails) {
    var count = 0
    for (var i = 0; i < activeTenancyDetails.length; i++) {
      if (activeTenancyDetails[i].stayDuration < 0) {
        count = count + 1
      }
    }
    return count
  }

  function countTodayCheckoutGuests(activeTenancyDetails) {
    var count = 0
    for (var i = 0; i < activeTenancyDetails.length; i++) {
      if (activeTenancyDetails[i].stayDuration === 0) {
        count = count + 1
      }
    }
    return count
  }
  function countTmroCheckoutGuests(activeTenancyDetails) {
    var count = 0
    for (var i = 0; i < activeTenancyDetails.length; i++) {
      if (activeTenancyDetails[i].stayDuration === 1) {
        count = count + 1
      }
    }
    return count
  }
  function countTotalRent(activeTenancyDetails) {
    var count = 0
    for (var i = 0; i < activeTenancyDetails.length; i++) {
      count += activeTenancyDetails[i].rate
    }
    return count
  }

  // FIXME: export the table to excel
  return (
    <>
      <Header />
      {/* <Table>
        <thead>
        <th>Id</th>
        <th>Guests</th>
        <th>Phone Numbers</th>
        <th>Checkin</th>
        <th>Checkout</th>
        <th>Rent</th>
        <th>Deposit</th>
        </thead>
        <tbody>
        {activeTenancyDetails.map((row, index) => (
            <tr>
                <td>{row.id}</td>
                <td>{row.names}</td>
                <td>{row.phoneNumbers}</td>
                <td>{row.checkin}</td>
                <td>{row.checkout}</td>
                <td>{row.rate}</td>
                <td>{row.deposit}</td>
            </tr>
        ))}
        </tbody>
        
    </Table> */}
      <h4 className="mt-3 ms-5"> Today's Guests</h4>
      <h5 className="mt-0 me-5 mb-0" style={{ marginLeft: '50%' }}>
        Active Guests: {activeTenancyDetails.length}
        &nbsp; &nbsp; Overstayed Guests :{' '}
        {countOverstayedGuests(activeTenancyDetails)}
        &nbsp; &nbsp; Today Checkouts :{' '}
        {countTodayCheckoutGuests(activeTenancyDetails)}
        &nbsp; &nbsp; Tomorrow Checkouts :{' '}
        {countTmroCheckoutGuests(activeTenancyDetails)}{' '}
      </h5>
      <h5 style={{ marginLeft: '50%', color: 'white' }}>
        &nbsp; &nbsp; Total Rent : {'$' + countTotalRent(activeTenancyDetails)}{' '}
      </h5>
      <br></br>
      <Table
        className="ms-5"
        autoHeight
        // height={2000}
        wordWrap="break-word"
        //   data={activeTenancyDetails}
        data={getDataSorted(sortColumn, sortType, activeTenancyDetails)}
        size="lg"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        headerHeight={40}
        shouldUpdateScroll={false}
      >
        <Column width={100} fixed sortable>
          <HeaderCell>
            <b>Room </b>
          </HeaderCell>
          <Cell dataKey="roomid">
            {(rowData) => (
              <a
                onClick={(e) => {
                  // setValueTenancyId(row.id);
                  navigate(`/tenants/add/${rowData.id}`, {
                    state: { tenancyId: rowData.id },
                  })
                }}
              >
                <Link>{rowData.roomid}</Link>
              </a>
            )}
          </Cell>
        </Column>
        <Column width={140} sortable>
          <HeaderCell>Days </HeaderCell>
          <Cell style={{ padding: '6px' }} dataKey="stayDuration">
            {(rowData) => (
              <>
                {rowData.stayDuration < 0 ? (
                  <label style={{ color: 'red' }}>
                    Overstay {rowData.stayDuration}
                  </label>
                ) : rowData.stayDuration === 0 ? (
                  <label>Checkout Today</label>
                ) : (
                  <label>{rowData.stayDuration}</label>
                )}
              </>
            )}
          </Cell>
        </Column>

        <Column width={160} sortable>
          <HeaderCell>Initial Arrival Date</HeaderCell>
          <Cell dataKey="firstArrivalDate">
            {(rowData) => (
              <>
                {formatDateText(rowData.firstArrivalDate)}
                <br></br>
                {' ( ' +
                  dateDifference(rowData.firstArrivalDate, new Date()) +
                  ' days since )'}
              </>
            )}
          </Cell>
        </Column>
        {/* <Column width={100} sortable>
        <HeaderCell>Vacancy </HeaderCell>
        <Cell dataKey="Vacancy" />
      </Column> */}

        <Column width={180} sortable>
          <HeaderCell>Names</HeaderCell>
          <Cell dataKey="names" />
        </Column>

        <Column width={140} sortable>
          <HeaderCell>phoneNumbers</HeaderCell>
          <Cell dataKey="phoneNumbers" />
        </Column>

        <Column width={160} sortable>
          <HeaderCell>Checkin</HeaderCell>
          <Cell dataKey="checkin">
            {(rowData) => <>{formatDateText(rowData.checkin)}</>}
          </Cell>
        </Column>

        <Column width={150} sortable>
          <HeaderCell>Expected Checkout</HeaderCell>
          <Cell dataKey="checkout">
            {(rowData) => <>{formatDateText(rowData.checkout)}</>}
          </Cell>
        </Column>

        <Column width={100} sortable>
          <HeaderCell>Rent </HeaderCell>
          <Cell dataKey="rate">
            {(rowData) => (
              <>
                <b> $</b>
                {rowData.rate}
              </>
            )}
          </Cell>
        </Column>
        {/* <Column width={100} sortable>
        <HeaderCell>Balance </HeaderCell>
        <Cell dataKey="Balance">
        {rowData => (
            <>
              {rowData.rate - rowData.paidAmount}
            </>
          )}
        </Cell>
      </Column> */}

        {/* <Column  width={70}  sortable>
        <HeaderCell>Id</HeaderCell>
        <Cell style={{ padding: '6px' }}>
          {rowData => (
            <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
              Edit
            </Button>
          )}
        </Cell>
      </Column> */}
        <Column width={150} sortable>
          <HeaderCell>Tenant Review </HeaderCell>
          <Cell dataKey="guestQuality">
            {(rowData) =>
              rowData.reviewCount == '0' ? (
                <span>No Reviews</span>
              ) : (
                <span>
                  {rowData.averageOverallRating + ' '}
                  <a
                    style={{
                      cursor: 'pointer',
                      background: 'none',
                      color: 'blue',
                    }}
                    onClick={(e) => {
                      setTenantId(rowData.id)
                      setShowReview(true)
                    }}
                  >
                    {'[' + rowData.reviewCount + '] '} Reviews
                  </a>
                </span>
              )
            }
          </Cell>
        </Column>

        <Column width={220}>
          <HeaderCell>...</HeaderCell>
          <Cell>
            {(rowData) => (
              <>
                <Link
                  onMouseMove={(e) => setShowMakePaymentModal(false)}
                  onTouchMove={(e) => setShowMakePaymentModal(false)}
                  onClick={(e) => {
                    setTenancyId(rowData.id)
                    setShowMakePaymentModal(true)
                  }}
                >
                  Take Payment
                </Link>{' '}
                {/* <Divider vertical></Divider> */}
                <br></br>
                <Link
                  onMouseMove={(e) => setShowPaymentSummary(false)}
                  onTouchMove={(e) => setShowPaymentSummary(false)}
                  onClick={(e) => {
                    console.log(rowData)
                    setLongTermTenancyId(rowData.longTermTenancyId);
                    // setTenancyId(rowData.id)
                    // setShowSendMessageModal(true)
                    setShowPaymentSummary(true)
                  }}
                >
                 Payment Summary
                </Link>
                <br></br>
                {/* <Link
                  onMouseMove={(e) => setShowSendMessageModal(false)}
                  onTouchMove={(e) => setShowSendMessageModal(false)}
                  onClick={(e) => {
                    setTenancyId(rowData.id)
                    // setShowSendMessageModal(true)
                  }}
                >
                  Message Guest
                </Link> */}
                {/* 2 Days  */}
                {rowData.stayDuration <= 1 ? (
                  rowData.renewing == 1 ? (
                    <b style={{ color: 'green' }}>Renewing</b>
                  ) : rowData.renewing == 0 ? (
                    <b style={{ color: 'red' }}>Not Renewing</b>
                  ) : (
                    <>
                      <Link
                        onMouseMove={(e) => setShowFollowUpModal(false)}
                        onTouchMove={(e) => setShowFollowUpModal(false)}
                        onClick={(e) => {
                          setTenancyId(rowData.id)
                          setShowFollowUpModal(true)
                          setRowDataProp(rowData)
                        }}
                      >
                        <b style={{ color: 'red' }}>Follow up</b>
                      </Link>
                    </>
                  )
                ) : null}
              </>
            )}
          </Cell>
        </Column>
        {/* <Column width={80} fixed="right">
        <HeaderCell>...</HeaderCell>
        <Cell><Link onMouseMove={(e)=>setShowMakePaymentModal(false)} onClick={(e)=>setShowMakePaymentModal(true)}>Message Guest</Link></Cell>
      </Column> */}
      </Table>
      {showReview && (
        <CustomerReviewModal
          data={{ showReview, setShowReview, tenantId }}
        ></CustomerReviewModal>
      )}
      {showMakePaymentModal && (
        <MakePayment
          value={propsForMakePayment}

          // getBalanceDueByTenancyId={getBalanceDueByTenancyId}
        />
      )}
      {showPaymentSummary && <TenancyDetailsByLTTI 
      longTermTenancyId={longTermTenancyId}
      showTenancyDetailsByLTTIModal={showPaymentSummary}
      setShowTenancyDetailsByLTTIModal={setShowPaymentSummary}
      />}
      {showSendMessageModal && <SendSMS />}
      {showFollowUpModal && (
        <AddFollowUp
          showFollowUpModal={showFollowUpModal}
          data={rowDataProp}
          activeTenancyDetails={activeTenancyDetails}
          setShowFollowUpModal={setShowFollowUpModal}
        />
      )}
    </>
  )
}
const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData)
      }}
      icon={
        expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon
          // onClick={(e)=>alert("Saranya")}
          />
        )
      }
    />
  </Cell>
)

export default AllTenants
