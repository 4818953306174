

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, redirect, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import $ from 'jquery';
import Header from "./Header.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser,faSearch,faCreditCard,faEnvelope,faPhone,faBuilding,faFlag,faDollar } from '@fortawesome/free-solid-svg-icons'
import {dateDifference, formatDate, parseResponseJSON, getDataSorted, formatDateText} from "./CommonFunctions";
import {Form,Row,Col,Button,Modal} from "react-bootstrap"
import MakePayment from "./MakePayment";
import { Message,Table } from "rsuite";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { NumericFormat,PatternFormat } from "react-number-format";
import { parseISO,eachDayOfInterval, format } from "date-fns";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const { Column, HeaderCell, Cell } = Table;
const options = { month: 'short', day: 'numeric', year:'numeric' };
var totalAmountPaid=0,totalCashAmount=0,totalCreditAmount=0,totalOtherAmount=0;
var totalRent=0;
const AddPayment = () => {
    const [searchStartDate,setSearchStartDate]=useState(formatDate(new Date(),-6));
    const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date(),1));
    const [roomNumber,setRoomNumber]=useState("")
    // const [selectedTenancyDetails,setSelectedTenancyDetails]=useState({});
    const [isMakePaymentComponentVisible,setIsMakePaymentComponentVisible]=useState(false)
    const [paymentHistory,setPaymentHistory]=useState([]);
    const [customerDetails,setCustomerDetails]=useState([]);
    const [tenancyDetails,setTenancyDetails]=useState([]);
    // const [rroomIds,setRRoomIds]=useState([])
    // const [roomid,setRoomid]=useState(null)
    // const [tenantIds,setTenantIds]=useState([]);    
    const [paymentDetails,setPaymentDetails]=useState({})
    const [tenancyId,setTenancyId]=useState(null);
    const [paymentId,setPaymentId]=useState(null);
    const [allRooms,setAllRooms]=useState([]);
    const [isRoomNumberInSearch, setIsRoomNumberInSearch]=useState(false);
    const [dateColumnFilter,setDateColumnFilter]=useState("actualPaymentDate");
    const [isMissingPayments,setIsMissingPayments]=useState(false);
    const [missingPayments,setMissingPayments] = useState([]);
    // const [totalCashAmount,setTotalCashAmount]=useState(0);
    // const [totalCreditAmount,setTotalCreditCardAmount]=useState(0);
    // const [totalOtherAmount,setTotalOtherAmount]=useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortType, setSortType] = useState("asc");
    const [loading, setLoading] = useState(false);
    const [sortModel,setSortModel] = useState("checkin")
    const url=process.env.REACT_APP_SERVER_URL;
    var balance=0;
    var previd=0;
    var totalRunningBalance=0;
    let paymentid=null;
    const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
    
    
  
    

    useEffect(() => {
        // displayRooms();
        // getPaymentHistoryByTenantId();
        // getCustomerDetailsByTenantId(); 
        if (queryParams.size != 0) {
          let rn=queryParams.get('roomNumber');
          let st=queryParams.get('searchStartDate')
          let et=queryParams.get('searchEndDate')
          setRoomNumber(rn);
          setSearchStartDate(st);
          setSearchEndDate(et);
          getAllRooms();
          getRentedTenancyDetailsByRoomIdAndDateRange(null,st,et,rn);
        }
        else{
          getAllRooms();
          getRentedTenancyDetailsByRoomIdAndDateRange(null,searchStartDate,searchEndDate,roomNumber);
        }
        
      }, []);
      const getAllRooms=async()=>{
        try{
          await axios.get(`${url}/rooms`).then((responseForAllRooms)=>{
            console.log(responseForAllRooms.data);
            setAllRooms(responseForAllRooms.data);
          })
    
        }
        catch(error){
          console.log(error);
        }
       }
      const handleCloseEditPayment=()=>{
        setPaymentId("");
        setIsMakePaymentComponentVisible(false);
        setPaymentDetails({})
      }
      const handleEditPayment=(paymentDetails)=>{
        setPaymentId(paymentDetails.paymentId)
        // setTenancyId(tenancyId)
        setPaymentDetails(paymentDetails)
        setIsMakePaymentComponentVisible(true)
      }
  

      function calculateBalance (rent, paid_amount,id,nextIdIndex){
        // console.log("sbsdgvfyhdv");
        if(nextIdIndex==null){
          if(previd!=id)
          {
            // alert(id)
            balance=rent;
            previd=id;
          }
          if(balance<0){
            balance = paid_amount + balance ;
          }
          else{
            balance = paid_amount - balance ;}
        if ( balance < 0 ) {
        return <label> -  ${balance*-1}</label>
        }
        
        if ( balance >= 0) {
        return <label> ${balance}</label>;
        }
        
        return balance;
      }
      else{
        totalRent=totalRent+ rent;
        totalAmountPaid = totalAmountPaid + paid_amount;
        if(tenancyDetails[nextIdIndex]){
          var nextId= tenancyDetails[nextIdIndex].id;
          if(nextId!=id)
          {
            totalRunningBalance+=balance
            return <label> {totalRunningBalance} </label>;
          }
        }
        else{
          totalRunningBalance+=balance
          return <label> {totalRunningBalance} </label>;
        }
      }
      }


      

      function exportReportToExcel() {
        alert("converting this payment history page to excel doc")
        }
      function calculateCardandCashAmount(paymentData){
        totalRent=0
    totalAmountPaid=0;
    totalCashAmount=0;
    totalCreditAmount=0;
    totalOtherAmount=0;
        for(var i=0;i<paymentData.length;i++){
          if(paymentData[i].cashAmount)
            totalCashAmount+=paymentData[i].cashAmount;
          if(paymentData[i].creditCardAmount)
            totalCreditAmount+=paymentData[i].creditCardAmount;
          if(paymentData[i].otherAmount)
            totalOtherAmount+=paymentData[i].otherAmount;
        }
        totalAmountPaid+=totalCashAmount+totalCreditAmount+totalOtherAmount;
        let tenancyToRentDict={};
        for(var i=0;i<paymentData.length;i++){
          tenancyToRentDict[paymentData[i].id]=paymentData[i].rate;
        }
        console.log(tenancyToRentDict)
        for(var itr in tenancyToRentDict){
          console.log(tenancyToRentDict[itr])
          totalRent+=tenancyToRentDict[itr]
        }


      }
      const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };
      
    const getRentedTenancyDetailsByRoomIdAndDateRange=async(e,searchStartDate,searchEndDate,roomNumber)=>{
      if(e) e.preventDefault();
        try{
          if(isMissingPayments&&roomNumber){
            await axios.get(`${url}/missingPayments/${roomNumber}`).then((responseForTenancyDetails)=>{
              console.log(responseForTenancyDetails.data);
            setTenancyDetails(responseForTenancyDetails.data);
            setIsRoomNumberInSearch(true);
            
           
          })
            

          }
          else{
            if(roomNumber){
              await axios.post(`${url}/rentedTenancyDetailsByRoomId/${roomNumber}`,{
                  searchStartDate,
                  searchEndDate,
                  dateColumnFilter
              }).then(async(responseForTenancyDetails1)=>{
                const dateColumnFilter = 'actualPaymentDate';
                await axios.post(`${url}/getMissingPayments`,{
                    searchStartDate,
                    searchEndDate,
                    dateColumnFilter
                  }).then(async(responseForTenancyDetails2)=>{
                    if(responseForTenancyDetails2 && responseForTenancyDetails2.data){
                      let resultArray=responseForTenancyDetails1.data;
                      for(let itr in responseForTenancyDetails2.data){
                        if(responseForTenancyDetails2.data[itr].roomid==roomNumber)
                        resultArray.push(responseForTenancyDetails2.data[itr]);
                      }
                      setTenancyDetails(resultArray);
                    }
                    
                  
                  })
                
                setIsRoomNumberInSearch(true);
                calculateCardandCashAmount(responseForTenancyDetails1.data);
               
              })
            }
            else{
              
              await axios.post(`${url}/payments/allPaymentsinDateRange`,{
                searchStartDate,
                searchEndDate,
                dateColumnFilter
              }).then(async(responseForTenancyDetails)=>{
                console.log(responseForTenancyDetails.data)
                setTenancyDetails(responseForTenancyDetails.data);
                setIsRoomNumberInSearch(false);
                calculateCardandCashAmount(responseForTenancyDetails.data);
               
              })
            }
          }
          setSortModel("checkin")



        }catch(error){
            console.log(error)
        }
    }

    const getPaymentHistoryByRoomIdAndDateRange=async(e)=>{
      if(e) e.preventDefault();
      try{
        await axios.post(`${url}/paymentHistoryByRoomIdAndDateRange/${roomNumber}`,{
          searchStartDate,
          searchEndDate
        }).then((responseForPaymentHistory)=>{
          console.log(responseForPaymentHistory.data)
        })

      }catch(error){
        console.log(error)
      }
    }

    const getPaymentHistoryByTenantId=async(id)=>{
        try{
            const response=await axios.get(`${url}/paymentHistory/${id}`);
            setTenancyId(id);
            getCustomerDetailsByTenantId(id);

            setPaymentHistory(response.data)

        }catch(error){
            console.log(error)
        }
    }
    const getCustomerDetailsByTenantId=async(id)=>{
        try{
            
            const response=await axios.get(`${url}/tenants/${id}/customers`);
            setCustomerDetails(response.data);
            console.log(response.data)
            return response.data;
            // setPaidBy(response.data[0].fname+" "+response.data[0].lname)

        }catch(error){
            console.log(error)
        }
    }
    const columns=[
      { field: 'roomid', headerName: 'Room', width: 60, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          return <Link to={`/tenants/add/${currentRow.id}`} target="_blank">
          {currentRow.roomid}
          </Link>
        }
       },
      { field: 'paidBy', headerName: 'Paid by', width: 150, align: 'left' },

      { field: 'receiptNumber', headerName: 'Receipt Number', width: 120, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
            return currentRow.receiptNumber
        }
       },
      
      { field: 'checkin', headerName: 'Tenancy Period', width: 240, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          // if(currentRow.paymentId)
          return <Link to={`/tenants/add/${currentRow.id}`} target="_blank">
          {parseISO(currentRow.checkin).toLocaleDateString('en-us',options)
          + '  -  ' +
          parseISO(currentRow.checkout).toLocaleDateString('en-us',options)
          }
          </Link>
          
         }
      },
      { field: 'rate', headerName: 'Rent', width: 90, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          return <>
          <NumericFormat value={currentRow.rate} thousandSeparator prefix={'$'} displayType="text"/>
          </>
          
         }
       },
  
      { field: 'paidAmount', headerName: 'Amount Paid', width: 90, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          return <b>
          <NumericFormat value={currentRow.paidAmount} thousandSeparator prefix={'$'} displayType="text"/>
          </b>
          
         }
       },
      
      { field: 'cashAmount', headerName: 'Cash Amount', width: 140, align: 'left',
        renderCell:(params)=>{
        const currentRow=params.row;
        if(currentRow.cashAmount)
        return <>
        <NumericFormat value={currentRow.cashAmount} thousandSeparator prefix={'$'} displayType="text"/>
        </>
        return "-";
        
       } },
      { field: 'creditCardAmount', headerName: 'Credit Card Amount', width: 140, align: 'left', 
        renderCell:(params)=>{
          const currentRow=params.row;
        if(currentRow.creditCardAmount)
          return <>
          <NumericFormat value={currentRow.creditCardAmount} thousandSeparator prefix={'$'} displayType="text"/>
          </>
        return "-";

          
         }
      },
  
      { field: 'otherAmount', headerName: 'Other', width: 120, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
        if(currentRow.otherAmount)
          return <>
          <NumericFormat value={currentRow.otherAmount} thousandSeparator prefix={'$'} displayType="text"/>
          </>
        return "-";

          
         }
       },
       { field: 'paymentDate', headerName: 'System Payment Date', width: 140, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          if(currentRow.paymentDate)
          return parseISO(currentRow.paymentDate).toLocaleDateString('en-us',options)
        return null;
        }
       },
       { field: 'actualPaymentDate', headerName: 'Payment Date', width: 140, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          if(currentRow.actualPaymentDate)
          return parseISO(currentRow.actualPaymentDate).toLocaleDateString('en-us',options)
        return null;
        }
       },
       { field: 'status', headerName: 'Tenancy Status', width: 140, align: 'left',
        renderCell:(params)=>{
          return params.row.status;
        }
       },
       { field: 'actions', headerName: 'Actions', width: 100, align: 'left',
        renderCell:(params)=>{
          const currentRow=params.row;
          return <Link onMouseUp={handleCloseEditPayment} 
          onTouchMove={handleCloseEditPayment}
          onClick={()=>handleEditPayment(currentRow)}>Edit</Link>

          
         }
       },

      // { field: 'balance', headerName: 'Balance', width: 120, align: 'left',
      //   renderCell:(params)=>{
      //     const row=params.row;
      //     return <>
      //     <NumericFormat value={calculateBalance(row.rate, row.paidAmount,row.id,null)} thousandSeparator prefix={'$'} displayType="text"/>
      //     </>
          
      //    }
      //  },
    ]
    function CustomPagination(props) {
      return <>
      <b>Expected Rent:
      <NumericFormat value={totalRent} thousandSeparator prefix={'$'} displayType="text"/></b>
      <b>Amount Received:
      <NumericFormat value={totalAmountPaid} thousandSeparator prefix={'$'} displayType="text"/></b>
          <b>Cash Amount: 
          <NumericFormat value={totalCashAmount} thousandSeparator prefix={'$'} displayType="text"/></b>
          <b>Credit Amount: 
          <NumericFormat value={totalCreditAmount} thousandSeparator prefix={'$'} displayType="text"/></b>
          <b>Other Amount: 
      <NumericFormat value={totalOtherAmount} thousandSeparator prefix={'$'} displayType="text"/></b>  
          
          <GridPagination  {...props} /> </>;
    }

    return(
        <div>
            <Header/>
            <div className="container">
            <Grid container spacing={2} sx={{m: 1}}>
            <Grid item xs={12} sm={12} md={2.5}>
            <FormControl  sx={{ minWidth: 150 }}>
              <InputLabel id="demo-simple-select-label"
              >Room Number</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={roomNumber}
                onChange={(e)=>{setRoomNumber(e.target.value)}}
                label="Room Number"
                sx={{
                  // '& .MuiInputBase-input': {
                  //   height:"2em"
                  // }
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {allRooms.map((row,index)=>(
                  <MenuItem value={row.id}>{row.id}</MenuItem>
                ))}
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                defaultValue={dayjs(searchStartDate)} 
                label="Start Date"
                // views={['year', 'month', 'day']}
                onChange={(value) => {
                  const formattedDate = value ? value.format('YYYY-MM-DD') : null;
                  console.log(formattedDate);
                  setSearchStartDate(formattedDate);
                }}
              />
            </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                defaultValue={dayjs(searchEndDate)} 
                label="End Date"
                // views={['year', 'month', 'day']}
                onChange={(value) => {
                  const formattedDate = value ? value.format('YYYY-MM-DD') : null;
                  console.log(formattedDate);
                  setSearchEndDate(formattedDate);
                }}
              />
            </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={0.5}> 
              {/* Selected <br></br> 
            {dateDifference(searchStartDate,searchEndDate)} Days */}
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
            <Button  onClick={(e)=>getRentedTenancyDetailsByRoomIdAndDateRange(e,searchStartDate,searchEndDate,roomNumber)}>
            Search for Payments</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
            <Button 
                // onClick={(e)=>handleShowMakePaymentPopup()}
                onMouseUp={()=>{{setIsMakePaymentComponentVisible(false)}}}
                onTouchMove={()=>{{setIsMakePaymentComponentVisible(false)}}}
                onClick={()=>{setPaymentId("");setPaymentDetails({});setIsMakePaymentComponentVisible(true)}}
                >Make Payment</Button>
            </Grid>
            </Grid>
            <Grid container spacing={2} sx={{m: 1}}>
            <Grid item xs={12} sm={12} md={2.5}></Grid>
            <Grid item xs={12} sm={12} md={2}><Col style={{display: "flex"}}> 
              <Form.Check size="lg" type="radio" name="radioGroup" 
                onChange={()=>setDateColumnFilter("systemPaymentDate")}></Form.Check>
              <b> &nbsp;System Payment Date </b></Col>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
            <Col style={{display: "flex"}}> 
              <Form.Check size="lg" type="radio" name="radioGroup" checked={dateColumnFilter === "actualPaymentDate"}
                onChange={()=>setDateColumnFilter("actualPaymentDate")}></Form.Check>
              <b>  &nbsp;Actual Payment Date </b></Col>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
            <Col style={{display: "flex"}}> 
              <Form.Check size="lg" type="radio" name="radioGroup"
                onChange={()=>setDateColumnFilter("checkinDate")}/> 
              <b>  &nbsp;Checkin Date </b></Col>
            </Grid>
            <Grid item xs={12} sm={12} md={3.5}></Grid>
            </Grid>
            <br></br>
            {isRoomNumberInSearch && tenancyDetails.length>0  
              && searchStartDate > tenancyDetails[0].firstArrivalDate
              && <Message className="fs-5" showIcon type="warning" closable>
              Guest moved in on {tenancyDetails[0].firstArrivalDate }. Please use this date as the search start date to get all relevant records
            </Message> }

            </div>
            <br></br>
            
           {/* FIXME : This condition applies to search end date as well. Please fix */}
            
            
            {isMakePaymentComponentVisible  && <MakePayment value={{paymentDetails:paymentDetails,customerDetails:customerDetails}}
            getCustomerDetailsByTenantId={getCustomerDetailsByTenantId}
            getRentedTenancyDetailsByRoomIdAndDateRange={getRentedTenancyDetailsByRoomIdAndDateRange}
            />}
            {/* {isMakePaymentComponentVisible && Object.keys(paymentDetails).length > 0 && <MakePayment value={{paymentDetails:paymentDetails}}/>} */}
            
            

              {tenancyDetails.length ? 


<>
{/* <table className="table is-striped is-fullwidth" id="payment-history">
          <thead>
            <tr>
               <th>Room</th>
               <th>Paid By</th>
               <th>Receipt Number</th>
              <th>Checkin</th>
              <th>Checkout</th>
              
              <th>Room Rate</th>
              <th>Payment</th>
              <th>Cash Amount</th>
              <th>Credit card Amount</th>
              <th>Other Amount</th>
              <th>System Payment Date</th>
              <th>Actual Payment Date</th>
              <th>Balance</th>
              
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tenancyDetails.map((row, index) => (
              <tr>
                <td><Link to={`/tenants/add/${row.id}`}>{row.roomid}</Link></td>
                <td>{row.paidBy}</td>
                <td>{row.receiptNumber}</td>
                 <td>{row.checkin}</td>
                <td>{row.checkout}</td>
                
                
                <td><b>$ </b>{row.rate}</td>
                {row.paidAmount ==0 ? <td> No Payment </td> : <td> ${row.paidAmount} </td>}
                <td>{row.cashAmount ? "$"+row.cashAmount : "-"}</td>
                <td>{row.creditCardAmount ? "$"+row.creditCardAmount : "-"}</td>
                <td>{row.otherAmount ? "$"+row.otherAmount : "-"}</td>
                <td>{row.paymentDate!=null ? new Date(row.paymentDate).toString().substring(0,16) : ""}</td>
                <td>{row.actualPaymentDate}</td>
                <td>{ calculateBalance(row.rate, row.paidAmount,row.id,null)}</td> 
                <td style={{display:"none"}}>{calculateBalance(row.rate,row.paidAmount,row.id,index+1)}</td>
                
                
       
          <td><Link onMouseUp={handleCloseEditPayment} 
          onTouchMove={handleCloseEditPayment}
          onClick={()=>handleEditPayment(row)}>Edit</Link><br></br></td>
              </tr>
            ))}


          <tr>
            <td></td><td></td><td></td><td></td><td></td>
            <td> <b> Expected rent: ${totalRent} </b> </td>
            <td> <b> Amount Received: ${totalAmountPaid} </b> </td>
            <td><b>Cash: ${totalCashAmount}</b></td>
            <td><b>Card: ${totalCreditAmount}</b></td>
            <td><b>Others: ${totalOtherAmount}</b></td>
            <td>
              </td>
            <td></td>
            <td></td>
          </tr>
          
          </tbody>
        </table> */}

<Box
        sx={{
          height: 550,
          width: '96%',
          marginLeft:"2%",

          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
      <DataGrid rows={tenancyDetails} columns={columns}
        getRowId={(row) => row.paymentId + ' ' + row.id}
        slots={{
          pagination: CustomPagination,
        }}
        sortModel={sortModel}
        onSortModelChange={(e)=>setSortModel(e)}
        />
        </Box>
        








        </>
         : <Message showIcon type="error" closable>
         No transactions in the selected Date Range
       </Message>} 

       


            
               <br></br>

           
        </div>
    )
};
export default AddPayment;



