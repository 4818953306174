import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert,
    Grid,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
   

} from "@mui/material"

import Header from "../Header";
import { NumericFormat,PatternFormat } from "react-number-format";
import CardGroup from "./CardGroup";
import ChartGroup from "./ChartGroup";
import { dateDifference, formatDate, formatDateText } from "../CommonFunctions";
import { parseISO,eachDayOfInterval, format } from "date-fns";
import {
  getAllActiveTenantsInfo,
  getFreeRoomsByDate,
  getCurrentInventory
} from "./DataConnections"
import { XAxis, YAxis, LineChart, CartesianGrid, Line, ResponsiveContainer, Tooltip } from "recharts";
let last12Months=[];
const options = { month: 'short', day: 'numeric', year:'numeric' };
let totalMonthlyAmount=0;
let totalExpectedAmount=0;
let currentVacancy=0;
let avgRooms=0;
let repairs=0;
let inventoryCount=0;
const now=new Date();
now.setDate(1);
const ExpectedVsActual=()=>{
  const currentDate=formatDate(new Date());

  const [searchStartDate,setSearchStartDate]=  useState(formatDate(now));
  const [searchEndDate,setSearchEndDate]=useState(formatDate(new Date()));
  const [reportSummary,setReportSummary] = useState([])
  const [occupancyReport,setOccupancyReport] = useState([]);
  const [actualReport,setActualReport] = useState([])
  const [expectedReport,setExpectedReport] = useState([])
 
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
    //   getReportSummary(null,searchStartDate,searchEndDate);
    //   getVacancyReportByDateRange(searchStartDate,searchEndDate);
    }, [searchStartDate,searchEndDate]);
    useEffect(() => {
      getCurrentMonthTotalPayment(searchStartDate,searchEndDate);
      getExpectedRentEachDay(searchStartDate,searchEndDate);
    }, [currentDate]);

    
    const getExpectedRentEachDay = async(searchStartDate,searchEndDate) =>{
        try{
        //     const currentDate = new Date();
        // const searchEndDate=formatDate(currentDate)
        // currentDate.setDate(1);
        // const searchStartDate=formatDate(currentDate);
        totalExpectedAmount=0;
          const response=await axios.post(`${url}/tenants/checkins/dateRange`,
            {searchStartDate,searchEndDate});
            if(response){
                const data=response.data;
                var dictionary={}
                for(let itr in data){
                    const currentRow=data[itr];
                    if(!(currentRow.checkin in dictionary))
                    {
                    dictionary[currentRow.checkin]=0;
                    }
                    let temp=currentRow.rate;
                    dictionary[currentRow.checkin] +=temp;
                }
                const interval = {
                    start: parseISO(searchStartDate),
                    end: parseISO(searchEndDate)
                  };
                  const dates = eachDayOfInterval(interval).map(date => format(date, 'yyyy-MM-dd'));
                  const resultArray=[];
                  dates.forEach(date => {
                    if(date in dictionary){
                        resultArray.push({
                          date:formatDateToMonthAndDate(date),
                          amount:dictionary[date]
                        })
                        totalExpectedAmount+=dictionary[date];
                    }
                    else{
                      resultArray.push({
                        date:formatDateToMonthAndDate(date),
                        amount:0
                      })
            
                    }
                    
                  });
                  console.log(resultArray)
                  setExpectedReport(resultArray)
                  return resultArray;
            }
            return null;

        }
        catch(error){
            console.log(error)
      
          }
    }
    const getCurrentMonthTotalPayment =async(searchStartDate,searchEndDate)=>{
      try{
        // const currentDate = new Date();
        // const searchEndDate=formatDate(currentDate)
        // currentDate.setDate(1);
        // const searchStartDate=formatDate(currentDate);
        
          const response=await getAllActiveTenantsInfo(null,searchStartDate,searchEndDate);
          if(response){
            const resultArray=preProcess(response.data);
            let temp=0;
            for(let itr in resultArray){
              const currentRow=resultArray[itr];
              temp+=currentRow.amount;
            }
            totalMonthlyAmount=temp;
            setActualReport(resultArray);
            return resultArray


          }
          return null;
  
      }
      catch(error){
        console.log(error)
  
      }
    }
    const getReportSummary =async(e,searchStartDate,searchEndDate)=>{
      try{
          const response=await getAllActiveTenantsInfo(null,searchStartDate,searchEndDate);
          if(response){
            setReportSummary(response.data)
          }
  
      }
      catch(error){
        console.log(error)
  
      }
    }
    
    function formatDateToMonthAndDate(inputDate) {
      const date = parseISO(inputDate);
      const options = { month: 'short', day: 'numeric' };
      return date.toLocaleDateString('en-US', options);
  }
    const preProcess = (data) =>{
      var dictionary={}
      for(let itr in data){
        const currentRow=data[itr];
        if(!(currentRow.actualPaymentDate in dictionary))
        {
          dictionary[currentRow.actualPaymentDate]=0;
        }
        let temp=0;
        if(currentRow.cashAmount!==null){
          temp+=currentRow.cashAmount;
        } 
        if(currentRow.creditCardAmount!==null){
          temp+=currentRow.creditCardAmount;
        } 
        if(currentRow.otherAmount!==null){
          temp+=currentRow.otherAmount;

        }
        dictionary[currentRow.actualPaymentDate] +=temp;
      }
      const interval = {
        start: parseISO(searchStartDate),
        end: parseISO(searchEndDate)
      };
      const dates = eachDayOfInterval(interval).map(date => format(date, 'yyyy-MM-dd'));
      const resultArray=[];
      dates.forEach(date => {
        if(date in dictionary){
            resultArray.push({
              date:formatDateToMonthAndDate(date),
              amount:dictionary[date]
            })
        }
        else{
          resultArray.push({
            date:formatDateToMonthAndDate(date),
            amount:0
          })

        }
        
      });
      console.log(resultArray)
      
      return resultArray;
    }
    function combineBoth(actualReport,expectedReport){
        var dict={}
        let resultArray=[];
        for(var itr in actualReport){
            dict[actualReport[itr].date]=actualReport[itr].amount
        }
        for(var itr in expectedReport){
            if(expectedReport[itr].date in dict){
                resultArray.push({
                    date:expectedReport[itr].date,
                    actualAmount: dict[expectedReport[itr].date],
                    expectedAmount: expectedReport[itr].amount
                })
                }
         }
         
        return resultArray;
    }
    
  
   
    
    
    

    return (
      <>
      <Header/>
      <Paper style={{margin:"0% 10%", padding:"1.2% 0%"}} >
          <Box style={{margin:"0% 10%", display:"flex" , justifyContent:"space-around"}}>
            <TextField id="outlined-basic" label="Start Date" variant="outlined" 
            type="date" value={searchStartDate} onChange={(e)=>{
                  setSearchStartDate(e.target.value);
              }}
            />
            <TextField id="outlined-basic" label="End Date" variant="outlined" 
            type="date" value={searchEndDate} onChange={(e)=>{
              setSearchEndDate(e.target.value);
              }}
            />
            <b className="mt-3"
            > Selected for {dateDifference(searchStartDate,searchEndDate) + " days"}</b>
            <Button variant="contained" 
            onClick={(e)=>{
                getCurrentMonthTotalPayment(searchStartDate,searchEndDate);
      getExpectedRentEachDay(searchStartDate,searchEndDate);
            }}>
                Search</Button>
          </Box>
        </Paper>
        
        <br></br>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={2}>Expected Amount: ${totalExpectedAmount}</Grid>
        <Grid item xs={12} sm={12} md={2}>Collected Amount: ${totalMonthlyAmount}</Grid>
        <Grid item xs={12} sm={12} md={2}>
        <span
            style={{
            display: 'inline-block',
            width: '20px',     // Length of the line
            height: '2px',     // Thickness of the line
            backgroundColor: '#8884d8',  // Color of the line
            verticalAlign: 'middle',     // Aligns line with text
            }}
        />
        &nbsp;<b><i>Expected Amount</i></b>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
        <span
            style={{
            display: 'inline-block',
            width: '20px',     // Length of the line
            height: '2px',     // Thickness of the line
            backgroundColor: '#82ca9d',  // Color of the line
            verticalAlign: 'middle',     // Aligns line with text
            }}
        />
        &nbsp;<b><i>Actual Amount</i></b>
        </Grid>
        
        </Grid>
        <br></br>
        {/* <ResponsiveContainer> */}
        {/* <ResponsiveContainer width="100%" height="100%"> */}
      <LineChart width={1500} height={500} data={combineBoth(actualReport,expectedReport)} 
      margin={{ top: 10, right: 30, left: 30, bottom: 5 }}
      >
        <XAxis dataKey="date"/>
        <YAxis/>
        <Tooltip /> 
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Line type="monotone" dataKey="expectedAmount" stroke="#8884d8" />
        <Line type="monotone" dataKey="actualAmount" stroke="#82ca9d" />
    </LineChart>
    {/* </ResponsiveContainer> */}
      
      </>
    );

    
}
export default ExpectedVsActual;